<template>
  <div>
    <div class="row">
      <div class="offset-md-2 col-xl-8">
        <notifications group="notification" position="top right" />

        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group input-group-lg">
                  <input
                    id="newLoggerImei"
                    v-model="logger.imei"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Unesite IMEI"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group input-group-lg mb-0">
                  <input
                    id="newLoggerSerialNumber"
                    v-model="logger.serialNumber"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Serijski broj"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group input-group-lg mb-0">
                  <input
                    id="newLoggerMsisdnNumber"
                    v-model="logger.msisdnNumber"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="MSISDN"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <button
              @click="submit"
              type="reset"
              :disabled="!logger.imei"
              class="btn btn-primary btn-lg btn-block"
            >
              Dodaj Logger
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'LoggersNew',
  data() {
    return {
      logger: {
        imei: null,
        serialNumber: null,
        msisdnNumber: null,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loggers', route: '/loggers/list' },
      { title: 'Add New Logger' },
    ])

    this.getFocus('newLoggerImei')
  },
  methods: {
    submit: function (e) {
      e.preventDefault()

      ApiService.post('loggers', this.logger).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Logger neuspjesno dodan.',
        })

        this.logger.imei = ''
        this.logger.serialNumber = ''
        this.logger.msisdnNumber = ''
        this.getFocus('newLoggerImei')
      })
    },
    getFocus(id) {
      document.getElementById(id).focus()
    },
  },
}
</script>

<style scoped lang="scss"></style>
